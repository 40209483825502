export const home = {
  labels: {
    games: "Games",
    company: "A Empresa",
    facebook: "facebook",
    instagram: "instagram",
    twitter: "twitter",
    terms_of_use: "Termo de uso",
    privacy: "Termo de Privacidade",
  },
  tooltips: {
    go_to: {
      facebook: "Ir para o Facebook",
      instagram: "Ir para o Instagram",
      twitter: "Ir para o Twitter",
    },
  },
  games: {
    title: "Feature Games",
    resume: "PROTA LATEST",
    items: {
      hom: [
        "Junte-se a um Metaverso onde tudo quer te matar, e receba o máximo recompensas valiosas por sobreviver o máximo que puder",
        "Colete itens únicos, troque, ganhe e seja o melhor neste jogo roguelike em tempo real.",
      ],
      bricks: [
        "Um novo e empolgante jogo de tijolos foi lançado!",
        "Vença níveis de dificuldade crescente, ganhe power-ups incríveis e jogue para ser o melhor quebra-tijolos de todos os tempos!",
        "Desfrute de controles suaves, belos gráficos e jogabilidade envolvente em Bricks King.",
      ],
      cleaning: [
        "Você está pronto para se tornar seu próprio gerente e organizar sua empresa de limpeza?",
        "- Contrate trabalhadores, limpe locais e transforme-os em lugares bonitos",
        "- Gerencie atualizações, cuide de seus funcionários e garanta sua produtividade",
        "- Prove que você é o melhor gerente de limpeza!",
      ],
      woodmill: [
        "Descubra e aproveite a indústria madeireira. Torne-se o lenhador mais rico de todos os tempos!",
        "- Expanda seu império e torne-se o próximo milionário madeireiro.",
        "- Crie produtos de madeira, aumente sua produtividade.",
        "- Seja seu próprio gerente em Woodmill Craft Idle!",
      ],
    },
  },
  company: {
    about: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    ],
  },
  footer: {
    resume: "Prota Games é uma startup de mediatech & mobile games",
    copyright: "Prota Games, all rights reserved",
  },
};
