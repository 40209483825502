export const home = {
  labels: {
    games: "Games",
    company: "Company",
    facebook: "facebook",
    instagram: "instagram",
    twitter: "twitter",
    terms_of_use: "Terms of use",
    privacy: "Privacy Police",
  },
  tooltips: {
    go_to: {
      facebook: "Go to Facebook",
      instagram: "Go to Instagram",
      twitter: "Go to Twitter",
    },
  },
  games: {
    title: "Featured Games",
    resume: "PROTA LATEST",
    items: {
      hom: [
        "Join a Metaverse where everything wants to kill you, and receive the most valuable rewards for surviving as long as you can.",
        "Collect unique items, trade, earn and be the best in this real-time, roguelike game.",
      ],
      bricks: [
        "A new, exciting bricks game has been launched!",
        "Beat levels of increasing difficulty, earn amazing powerups, and play to be the best brick breaker ever!",
        "Enjoy smooth controls, beautiful graphics and engaging gameplay in Bricks King.",
      ],
      cleaning: [
        "Are you ready to become your own manager, and organize your cleaning company?",
        "- Hire workers, clear locations, and turn them into beautiful places",
        "- Manage upgrades, take care of your workers, and ensure their productivity",
        "- Prove that you are the best cleaner manager!",
      ],
      woodmill: [
        "Discover and enjoy the timber industry. Become the wealthiest lumberman ever!",
        "- Expand your empire and become the next lumber millionaire.",
        "- Create lumber products, enhance your productivity.",
        "- Be your own manager in Woodmill Craft Idle!",
      ],
    },
  },
  company: {
    about: [
      "Lorem Ipsum is simply <strong>dummy text</strong> of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    ],
  },
  footer: {
    resume: "Prota Games is a mediatech & mobile games startup",
    copyright: "Prota Games, all rights reserved",
  },
};
