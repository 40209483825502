export const config = {
  language: {
    default: process.env.VUE_APP_LANGUAGE,
    i18n: true,
    locales: [
      {
        label: "common.languages.pt_br",
        value: "pt-BR",
      },
      {
        label: "common.languages.en_us",
        value: "en-US",
      },
    ],
  },
  body: {
    title: "Prota Games",
    background: "#ededed",
    desktop: {
      padding: "3rem",
    },
    mobile: {
      padding: "1rem",
    },
    padding: "3rem",
    mobile_size: 800,
  },
  logotipo: {
    is_local: true,
    primary: "images/logo-prota.png",
    secondary: "images/logo-prota-secondary.png",
    white: "images/logo-prota-white.png",
    icon: "images/logo-prota-icon.png",
  },
  social_media: {
    social_color: "#fff",
    social_color_hover: "#fdee21",
    items: [
      {
        url: "#",
        label: "home.labels.facebook",
        title: "home.tooltips.go_to.facebook",
        image: {
          path: "icons/facebook.svg",
          local: true,
        },
      },
      {
        url: "#",
        label: "home.labels.twitter",
        title: "home.tooltips.go_to.twitter",
        image: {
          path: "icons/twitter.svg",
          local: true,
        },
      },
      {
        url: "#",
        label: "home.labels.instagram",
        title: "home.tooltips.go_to.instagram",
        image: {
          path: "icons/instagram.svg",
          local: true,
        },
      },
    ],
  },
  side_menu: {
    active: false,
  },
  header_menu: {
    active: true,
    logo: {
      path: "images/logo-prota-secondary.png",
      local: true,
    },
    base_url: "/",
    height: "5rem",
    background: "#1a1a1a",
    color: "#fff",
    color_hover: "#fdee21",
    font: "Roboto-Bold",
    font_uppercase: true,
    items: [
      {
        label: "home.labels.games",
        url: "/",
        url_external: false,
      },
      // {
      //   label: "home.labels.company",
      //   url: "/company",
      //   url_external: false,
      // },
    ],
  },
  float_menu: {
    active: true,
    height: "4rem",
  },
  footer: {
    active: true,
    logo: "images/logo-prota-secondary.png",
    logo_is_local: true,
    base_url: "/",
    resume: "home.footer.resume",
    background: "#1a1a1a",
    font: "Roboto",
    font_color: "#fff",
    copyright: {
      year: "2023",
      resume: "home.footer.copyright",
      background: "#000",
      font_uppercase: true,
    },
    menu: {
      color: "#fff",
      color_hover: "#fdee21",
      font: "Roboto-Bold",
      font_uppercase: true,
      items: [
        {
          label: "home.labels.games",
          url: "/",
          url_external: false,
        },
        // {
        //   label: "home.labels.company",
        //   url: "https://www.google.com",
        //   url_external: true,
        // },
        {
          label: "home.labels.terms_of_use",
          url: "/termsofuse",
          url_external: false,
        },
        {
          label: "home.labels.privacy",
          url: "/privacy",
          url_external: false,
        },
      ],
    },
  },
  pages: {
    home: {
      banner: {
        active: true,
        height: "31rem",
        background: "#303030",
        items: [
          {
            path: "images/banner-innovate.png",
            local: true,
          },
          {
            path: "images/banner-technology.jpg",
            local: true,
          },
        ],
      },
      feature_games: {
        active: true,
        invert: true,
        center: true,
        title: "home.games.title",
        resume: "home.games.resume",
        font: "Roboto-Bold",
        font_size: "2.5rem",
        padding_block: "3rem",
      },
      games: {
        active: true,
        direction: "row",
        background: "#fff",
        gap: "1rem",
        image_width: "100%",
        padding_block: "3rem",
        items: [
          {
            logo: {
              path: "images/logo-hom.png",
              width: "70%",
              local: true,
            },
            image: {
              path: "images/mock-hom.png",
              width: "50%",
              local: true,
            },
            resume: {
              text: "home.games.items.hom",
              long_text: true,
            },
          },
          {
            logo: {
              path: "images/logo-bricks-king.png",
              width: "60%",
              local: true,
            },
            image: {
              path: "images/mock-bricks-king.png",
              width: "50%",
              local: true,
            },
            resume: {
              text: "home.games.items.bricks",
              long_text: true,
            },
          },
          {
            logo: {
              path: "images/logo-cleaning-idle.png",
              width: "60%",
              local: true,
            },
            image: {
              path: "images/mock-cleaning-idle.png",
              width: "50%",
              local: true,
            },
            resume: {
              text: "home.games.items.cleaning",
              long_text: true,
            },
          },
          {
            logo: {
              path: "images/logo-woodmill.png",
              width: "60%",
              local: true,
            },
            image: {
              path: "images/mock-woodmill.png",
              width: "50%",
              local: true,
            },
            resume: {
              text: "home.games.items.woodmill",
              long_text: true,
            },
          },
        ],
      },
      company_about: {
        active: false,
        center: true,
        padding_block: "3rem",
        image: {
          path: "images/logo-prota.png",
          width: "13.5rem",
          local: true,
        },
        resume: {
          long_text: true,
          text: "home.company.about",
          font_size: "1rem",
        },
      },
    },
    company: {
      padding_block: "2rem",
      title: {
        text: "company.title",
        font: "Roboto-Bold",
        font_size: "1.75rem",
      },
      resume: {
        text: "company.resume",
        font: "Roboto",
        long_text: true,
      },
    },
    privacy: {
      padding_block: "2rem",
      title: {
        text: "terms.privacy.title",
        font: "Roboto-Bold",
        font_size: "1.75rem",
      },
      resume: {
        text: "terms.privacy.resume",
        font: "Roboto",
        long_text: true,
      },
    },
    terms_use: {
      padding_block: "2rem",
      title: {
        text: "terms.terms_use.title",
        font: "Roboto-Bold",
        font_size: "1.75rem",
      },
      resume: {
        text: "terms.terms_use.resume",
        font: "Roboto",
        long_text: true,
      },
    },
  },
};
